// External Dependencies
import { GridColDef } from '@mui/x-data-grid-pro';
import { UniformIndexResponse, UniformIndexResponseItem } from '@presto-assistant/api_types/api/v1/uniform';
import { getOperationName } from '@apollo/client/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import { useQueryClient } from '@tanstack/react-query';
import CloudUploadIcon from 'mdi-material-ui/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogContentText from '@mui/material/DialogContentText';
import React, { useCallback, useMemo, useState } from 'react';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import TieIcon from 'mdi-material-ui/Tie';

// Internal Dependencies
import { ConfirmationDialog, DialogPermissionRequired, TableDataGrid } from 'components/shared';
import { DELETE_UNIFORMS, useCheckInOneUniformAtATime, useCheckInUniform } from 'gql/mutations';
import {
  GET_UNIFORMS_INDEX,
  useGetUniform,
} from 'gql/queries';
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import { apiClient } from 'utils/apiClient';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { evictApolloCache } from 'gql/client';
import { evictQueryCache } from 'utils/lib/graphql';
import { hasPermission } from 'state/self/selectors';
import { open } from 'state/ui/uniformFileUploadDialog/actions';
import { pluralize } from 'utils';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { useInfinitePaginatedListQuery } from 'hooks/usePaginatedListQuery';
import { useIsOpen } from 'hooks/useIsOpen';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import DeleteDialog from 'components/shared/DeleteDialog';
import DialogUniformFileUpload from 'components/shared/DialogUniformFileUpload';
import PercentageProgressBar from 'components/shared/PercentageProgressBar';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';

// Local Dependencies
import { useColumns } from './hooks';
import QuickCheckoutDialog from './QuickCheckoutDialog';

// Local Variables
const handleTableClickRow = (id: string) => `/${PATHS.UNIFORMS}/${id}`;
const dataSelector = (response: UniformIndexResponse) => response?.data;
const fullCountSelector = (response: UniformIndexResponse) => response?.fullCount;

// Component Definition
const UniformTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [checkInAllProgress, setCheckInAllProgress] = useState(0);
  const [deleteUniformId, setDeleteUniformId] = useState<string | null>(null);
  const [checkInUniformId, setCheckInUniformId] = useState<string | null>(null);
  const [
    quickCheckoutUniformId,
    setQuickCheckoutUniformId,
  ] = useState<string | null>(null);
  const [isCheckingInAllUniforms, setIsCheckingInAllUniforms] = useState(false);

  const {
    isOpen: isDeletePermissionDialogOpen,
    toggleIsOpen: toggleIsDeletePermissionDialogOpen,
  } = useIsOpen();

  const {
    handleClose: handleCloseCheckInAllUniformsDialog,
    handleOpen: handleOpenCheckInAllUniformsDialog,
    isOpen: isCheckInAllUniformsDialogOpen,
  } = useIsOpen();

  const handleNavigateToUniformCheckout = useCallback((row: UniformIndexResponseItem) => {
    navigate(`/${PATHS.UNIFORM_CHECKOUTS}/new?uniformId=${row.id}`);
  }, [navigate]);

  const fetchData = useCallback(async (queryParams: {
    limit: number;
    page: number;
  }) => {
    return apiClient.v1.uniform.index(queryParams);
  }, []);

  const {
    data,
    fullCount,
    isLoading,
  } = useInfinitePaginatedListQuery<UniformIndexResponse, UniformIndexResponseItem>({
    dataSelector,
    fullCountSelector,
    pageSize: 500,
    queryKey: ['uniformsIndex'],
    request: fetchData,
  });

  // Create a filtered list of all uniforms that are checked out
  const uniformsToCheckIn = data?.filter((uniform) => uniform.checkedOutToMemberId);

  const canCreateUniforms = useSelector(hasPermission('uniforms', 'write'));
  const canEditUniforms = useSelector(hasPermission('uniforms', 'edit'));
  const canDeleteUniforms = useSelector(hasPermission('uniforms', 'delete'));
  const canCreateUniformCheckouts = useSelector(hasPermission('uniformCheckouts', 'write'));
  const canEditUniformCheckouts = useSelector(hasPermission('uniformCheckouts', 'edit'));

  const { data: uniformCheckinData } = useGetUniform(checkInUniformId);

  const uniformToCheckIn = uniformCheckinData?.uniform;

  const handleOpenDialogUniformFileUpload = useCallback(() => {
    dispatch(open());
  }, [dispatch]);

  const handleCloseCheckInDialog = useCallback(() => {
    setCheckInUniformId(null);
  }, []);

  const [checkInUniform, {
    loading: isCheckingInUniform,
  }] = useCheckInUniform({
    onCompleted: () => {
      setCheckInUniformId(null);

      dispatch(
        addNotification('Uniform checked in!', 'success'),
      );

      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));
    },
  });

  const [checkInOneUniformAtATime] = useCheckInOneUniformAtATime();

  const toolbarActions = useMemo<IToolbarAction[]>(() => {
    const actions: IToolbarAction[] = [];

    if (canCreateUniforms) {
      actions.push({
        action: handleOpenDialogUniformFileUpload,
        icon: <CloudUploadIcon />,
        // TODO: Update this to the new way the API tells us about active
        // isDisabled: !self?.currentOrgActive,
        sectionTitle: 'Import',
        text: 'Import uniforms',
      });
    }

    if (canEditUniformCheckouts) {
      actions.push({
        action: handleOpenCheckInAllUniformsDialog,
        icon: <RemoveIcon />,
        isDisabled: !uniformsToCheckIn?.length,
        sectionTitle: 'Actions',
        text: 'Check in all uniforms',
      });
    }

    return actions;
  }, [
    canCreateUniforms,
    canEditUniformCheckouts,
    handleOpenDialogUniformFileUpload,
    handleOpenCheckInAllUniformsDialog,
    uniformsToCheckIn?.length,
  ]);

  const handleQuickCheckIn = useCallback((row: UniformIndexResponseItem) => {
    setCheckInUniformId(row.id);
  }, []);

  const handleConfirmCheckIn = useCallback(async () => {
    if (!uniformToCheckIn) {
      return;
    }

    const { activeCheckout } = uniformToCheckIn;

    if (!activeCheckout.active) {
      dispatch(
        addNotification('Uniform is not checked out!', 'error'),
      );
    }

    checkInUniform({
      variables: {
        uniformCheckoutId: activeCheckout.id,
      },
    });
  }, [
    checkInUniform,
    dispatch,
    uniformToCheckIn,
  ]);

  const handleConfirmCheckInAll = useCallback(async () => {
    try {
      setIsCheckingInAllUniforms(true);

      // eslint-disable-next-line no-restricted-syntax
      for (const uniform of uniformsToCheckIn ?? []) {
        if (uniform.checkoutId) {
          // eslint-disable-next-line no-await-in-loop
          await checkInOneUniformAtATime({
            variables: {
              uniformCheckoutId: uniform.checkoutId,
            },
          });

          setCheckInAllProgress((prevProgress) => prevProgress + 1);
        }
      }

      evictApolloCache('studentsIndex');

      setIsCheckingInAllUniforms(false);

      handleCloseCheckInAllUniformsDialog();

      evictQueryCache(queryClient, ['uniform']);

      dispatch(updateIsPaginatedListDataLoaded({
        isPaginatedListDataLoaded: false,
      }));

      dispatch(
        addNotification('All uniforms checked in!', 'success'),
      );
    } finally {
      setIsCheckingInAllUniforms(false);
      setCheckInAllProgress(0);
    }
  }, [
    checkInOneUniformAtATime,
    dispatch,
    handleCloseCheckInAllUniformsDialog,
    queryClient,
    uniformsToCheckIn,
  ]);

  const handleCloseQuickCheckoutDialog = useCallback(() => {
    setQuickCheckoutUniformId(null);
  }, []);

  const handleDeleteUniform = useCallback(
    (row: UniformIndexResponseItem) => {
      if (!canDeleteUniforms) {
        toggleIsDeletePermissionDialogOpen();
      } else {
        setDeleteUniformId(row.id);
      }
    },
    [canDeleteUniforms, toggleIsDeletePermissionDialogOpen],
  );

  const handleCloseDeleteDialog = useCallback(() => {
    setDeleteUniformId(null);
  }, []);

  const checkInAllUniformsContent = useMemo(() => (
    <>
      <DialogContentText gutterBottom>
        Your organization has{' '}
        <DialogContentText
          component="span"
          sx={{ fontSize: '1.2rem', fontWeight: 500 }}
          variant="body1"
        >
          {uniformsToCheckIn?.length}
        </DialogContentText>{' '}
        {pluralize(uniformsToCheckIn?.length, 'uniform')}{' '}
        checked out right now.
      </DialogContentText>

      {isCheckingInAllUniforms ? (
        <>
          <DialogContentText gutterBottom>
            Checking in all uniforms...
          </DialogContentText>

          <PercentageProgressBar
            denominator={uniformsToCheckIn?.length ?? 1}
            hideCaption
            label="Check in progress"
            numerator={checkInAllProgress}
          />
        </>
      ) : (
        <>
          <DialogContentText gutterBottom>
            You can check them in all at once.
          </DialogContentText>

          <DialogContentText>
            Buckle up — this might take several seconds.
          </DialogContentText>
        </>
      )}
    </>
  ), [
    checkInAllProgress,
    isCheckingInAllUniforms,
    uniformsToCheckIn?.length,
  ]);

  const extraColumns = useMemo<GridColDef<UniformIndexResponseItem>[]>(() => {
    const actionsColumn = createDataGridActionsColumn<UniformIndexResponseItem>([
      ...(canCreateUniformCheckouts
        ? [
          {
            action: handleNavigateToUniformCheckout,
            icon: <TieIcon />,
            text: 'Add uniform checkout',
          },
        ]
        : []),
      ...(canEditUniformCheckouts
        ? [
          {
            action: handleQuickCheckIn,
            icon: <RemoveIcon />,
            isDisabled: (row: UniformIndexResponseItem) => !row.checkedOutToMemberId,
            text: 'Quick check-in',
          },
        ]
        : []),
      {
        action: handleDeleteUniform,
        icon: <DeleteIcon />,
        text: 'Delete uniform item',
      },
    ]);

    return actionsColumn ? [actionsColumn] : [];
  }, [
    canCreateUniformCheckouts,
    canEditUniformCheckouts,
    handleQuickCheckIn,
    handleDeleteUniform,
    handleNavigateToUniformCheckout,
  ]);

  const columnsArgs = useMemo(
    () => ({
      extraColumns,
      onClickQuickCheckout: setQuickCheckoutUniformId,
    }),
    [extraColumns],
  );
  const columns = useColumns(columnsArgs);

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={
            canCreateUniforms
              ? {
                label: 'Uniform',
                to: `/${PATHS.UNIFORMS}/new`,
              }
              : null
          }
          checkboxSelection={canEditUniforms}
          clickRowTo={handleTableClickRow}
          columns={columns}
          components={{
            NoRowsOverlay: TableDataGridZeroState,
          }}
          globalEditResource="uniform"
          loading={isLoading}
          rows={data}
          tableResource="uniforms"
          toolbarActions={toolbarActions}
          withSearch
        />
      </DataGridContainer>

      <DialogUniformFileUpload />

      <DialogPermissionRequired
        isOpen={isDeletePermissionDialogOpen}
        onClose={toggleIsDeletePermissionDialogOpen}
      />

      <DeleteDialog
        clearCachePredicates={['uniforms']}
        context={['uniform']}
        isOpen={Boolean(deleteUniformId)}
        mutation={DELETE_UNIFORMS}
        onClose={handleCloseDeleteDialog}
        reduxTableKey="uniforms"
        refetchQueries={() => [getOperationName(GET_UNIFORMS_INDEX) as string]}
        singleItemId={deleteUniformId}
        size={fullCount}
        withNote
      />
      <ConfirmationDialog
        confirmButtonAction={handleConfirmCheckIn}
        confirmButtonText="Yes, check in"
        declineButtonAction={handleCloseCheckInDialog}
        description="Are you sure you want to check in this uniform?"
        handleClose={handleCloseCheckInDialog}
        isSubmitting={isCheckingInUniform}
        open={Boolean(checkInUniformId)}
        title="Check in uniform?"
      />
      <ConfirmationDialog
        confirmButtonAction={handleConfirmCheckInAll}
        confirmButtonText="Yes, check in all uniforms"
        declineButtonAction={handleCloseCheckInAllUniformsDialog}
        description={checkInAllUniformsContent}
        handleClose={handleCloseCheckInDialog}
        isSubmitting={isCheckingInAllUniforms}
        open={isCheckInAllUniformsDialogOpen}
        title="Check in all uniforms?"
        useCustomText
      />
      <QuickCheckoutDialog
        onClose={handleCloseQuickCheckoutDialog}
        uniformId={quickCheckoutUniformId}
      />
    </>
  );
};

export default UniformTable;
