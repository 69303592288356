// External Dependencies
import { NavigateFn, NavigateOptions } from '@reach/router';

// Local Dependencies
import { stringifyQueryParams } from './stringify_query_params';
import parseSearch from './parse_search';

export type NavigateSearchFn = (
  navigate: NavigateFn,
  searchOverrides: object,
  options?: NavigateOptions<any>,
) => void;

export const navigateSearch: NavigateSearchFn = (
  navigate,
  searchOverrides,
  options,
) => {
  const { pathname, search } = window.location;

  const parsedSearch = parseSearch(search);

  const newSearch = stringifyQueryParams({
    ...parsedSearch,
    ...searchOverrides,
  });

  navigate(`${pathname}?${newSearch}`, {
    replace: true,
    ...options,
  });
};
