// External Dependencies
import {
  GridFilterModel,
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarProps,
  gridFilteredSortedRowIdsSelector,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { blue } from '@mui/material/colors';
import { darken, lighten } from '@mui/material/styles';
import {
  globalEditResources,
} from '@presto-assistant/api_types/api/v1/globalEdit';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDebounce } from 'use-debounce';
import { useNavigate } from '@reach/router';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import GlobalEditIcon from '@mui/icons-material/AppRegistration';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import styled from 'styled-components';

// Internal Dependencies
import { NavigateSearchFn } from 'utils/lib/navigate_search';
import { useGetGlobalEditContract } from 'utils/api/globalEdit';
import { useIsOpen } from 'hooks/useIsOpen';
import GlobalEditDialog from 'components/shared/GlobalEditDialog';
import useTextField from 'hooks/useTextField';

// Local Dependencies
import {
  ConfirmationDialog,
  EnhancedIconButton,
  Flex,
  RefreshingIcon,
} from '..';
import { IToolbarAction } from '../DataTable/Toolbar';
import EditModeText from '../EditModeText';
import SubscriberAddButton, { SubscriberAddButtonProps } from '../SubscriberAddButton';
import ToolbarMoreActionsIconMenu from '../DataTable/ToolbarMoreActionsIconMenu';

// Local Typings
export interface EnhancedGridToolbarProps {
  addButtonProps?: SubscriberAddButtonProps | null;
  customAddButton?: React.ReactNode;
  filteredIds: GridRowId[];
  globalEditResource?: typeof globalEditResources[number];
  gridFilterModel: GridFilterModel;
  hideExport?: boolean;
  isEditMode?: boolean;
  isHydrating: boolean;
  onFilter?: (rowIds: GridRowId[]) => void;
  onToggleEditMode: () => void;
  onUpdateParams: NavigateSearchFn;
  rows: any[] | null;
  search?: string;
  selectedIds: GridRowId[];
  shouldNavigateOnSearch?: boolean;
  toolbarActions?: IToolbarAction[];
  withEditMode?: boolean;
  withGlobalEdit?: boolean;
  withSearch?: boolean;
}
type GlobalEditType = 'filtered' | 'selected';

// Local Variables
const StyledRoot = styled(Box)(({ theme }) => {
  const getColor = theme.palette.mode === 'light' ? darken : lighten;

  return {
    '&.searchAndActionsContainer': {
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
      },
    },
    '.bottomToolbarContainer': {
      padding: theme.spacing(0.5, 1.5, 1.5),
    },
    '.doneEditingButton': {
      marginLeft: theme.spacing(1.5),
    },
    '.editIconButton, .globalEditIconButton': {
      fontSize: '1.25rem',
      margin: theme.spacing(0, 0.5),
      padding: theme.spacing(1),
    },
    '.topToolbarContainer': {
      '&.editMode': {
        backgroundColor: theme.palette.mode === 'light'
          ? lighten(theme.palette.info.light, 0.9)
          : darken(blue[900], 0.5),
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        color: getColor(theme.palette.info.light, 0.6),
        paddingBottom: theme.spacing(1),
      },

      '@media print': {
        display: 'none',
      },

      padding: theme.spacing(1.5, 1.5, 0.5),
    },

    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    left: 0,
    position: 'sticky',
    right: 0,
  };
});

const StyledButton = styled.button(({ theme }) => ({
  '&.link-button': {
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.palette.grey['50'],
    cursor: 'pointer',
    display: 'inline',
    fontSize: 12,
    fontWeight: 'inherit',
    margin: 0,
    padding: 0,
    textDecoration: 'underline',
  },
}));

const EMPTY_SEARCH_STATE = [''];

// Component Definition
const EnhancedGridToolbar: React.FunctionComponent<EnhancedGridToolbarProps & GridToolbarProps> = ({
  addButtonProps,
  customAddButton,
  filteredIds,
  globalEditResource,
  gridFilterModel,
  hideExport,
  isEditMode,
  isHydrating,
  onFilter,
  onToggleEditMode,
  onUpdateParams,
  rows,
  search,
  selectedIds,
  setFilterButtonEl,
  toolbarActions: toolbarActionsProp,
  withEditMode,
  withSearch,
}) => {
  const navigate = useNavigate();
  const quickFieldProps = useTextField(search);

  const [globalEditType, setGlobalEditType] = useState<GlobalEditType>('filtered');

  const [searchValue] = useDebounce(quickFieldProps.value, 300);

  const apiContext = useGridApiContext();

  const {
    data: globalEditContract,
  } = useGetGlobalEditContract(globalEditResource ?? null);

  const currentApiContext = apiContext.current;

  const {
    handleClose, handleOpen, isOpen,
  } = useIsOpen();
  const {
    isOpen: isGlobalEditOpen,
    toggleIsOpen: toggleIsGlobalEditOpen,
  } = useIsOpen();

  const handleClickGlobalEdit = useCallback((type: GlobalEditType) => () => {
    setGlobalEditType(type);
    toggleIsGlobalEditOpen();
  }, [toggleIsGlobalEditOpen]);

  const toolbarActions = useMemo<IToolbarAction[] | null>(() => {
    const hasGlobalEdit = Boolean(globalEditContract?.data?.data.contract.fields.length);

    if (!hasGlobalEdit) {
      return toolbarActionsProp || null;
    }

    const globalEditToolbarActions: IToolbarAction[] = [
      {
        action: handleClickGlobalEdit('selected'),
        icon: <GlobalEditIcon />,
        isDisabled: selectedIds?.length === 0,
        sectionTitle: (
          <Flex>
            Global Edit
            {' '}
            <Chip
              color="primary"
              label="Beta"
              size="small"
              sx={{ marginLeft: 1 }}
            />
          </Flex>
        ),
        text: `Global edit selected (${selectedIds?.length ?? 0})`,
      },
      {
        action: handleClickGlobalEdit('filtered'),
        icon: <GlobalEditIcon />,
        isDisabled: filteredIds?.length === 0,
        text: `Global edit filtered (${filteredIds?.length ?? 0})`,
      },
    ];

    return toolbarActionsProp
      ? [...toolbarActionsProp, ...globalEditToolbarActions]
      : globalEditToolbarActions;
  }, [
    filteredIds,
    globalEditContract,
    handleClickGlobalEdit,
    selectedIds,
    toolbarActionsProp,
  ]);

  useEffect(() => {
    const newValue = searchValue ? searchValue?.toString().trim().split(' ') : EMPTY_SEARCH_STATE;

    currentApiContext.setQuickFilterValues(newValue);

    onUpdateParams(navigate, { q: searchValue });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () =>
      currentApiContext.setQuickFilterValues(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApiContext, navigate, onUpdateParams, searchValue]);

  useEffect(() => {
    // I don't know why, but we need the setTimeout to put this at the end of the event loop
    // otherwise, the onFilter does not get called on the first render
    setTimeout(() => {
      onFilter?.(gridFilteredSortedRowIdsSelector(apiContext));
    }, 0);
  }, [apiContext, gridFilterModel, onFilter, rows]);

  const handleClearQuickFilterValue = useCallback(() => {
    quickFieldProps.onReset();
  }, [quickFieldProps]);

  const shouldShowMoreActionsMenu = toolbarActions && toolbarActions.length > 0;

  const handleDisableEditMode = useCallback(() => {
    const cellWithEditState = Object.keys(apiContext.current.state.editRows);

    onToggleEditMode();

    // we wrap this in a setTimeout because we need to wait for any changes to be sent to the API
    setTimeout(() => {
      // If we have edit data but are trying to dismiss,
      // we need to throw away the edit data
      if (apiContext.current.state.tabIndex.cell && cellWithEditState.length) {
        apiContext.current.stopCellEditMode({
          field: apiContext.current.state.tabIndex.cell.field,
          id: cellWithEditState[0],
          ignoreModifications: true,
        });
      }
    }, 0);
  }, [apiContext, onToggleEditMode]);

  const sortingTipDescription = useMemo(() => {
    return (
      <Typography variant="body1">
        Hold down the <kbd>Ctrl</kbd> or <kbd>Shift</kbd> key
        (use <kbd>⌘</kbd> Command on macOS) while clicking the column header
        to sort the rows according to several criteria at the same time.
      </Typography>
    );
  }, []);

  const columnsButtonTooltipTitle = useMemo(() => (
    <Box sx={{ fontSize: 12 }}>
      <StyledButton
        className="link-button"
        onClick={handleOpen}
        type="button"
      >
        Learn more
      </StyledButton>{' '}
      about sorting columns.
    </Box>
  ), [handleOpen]);

  const doneEditingButton = useMemo(() => (
    <Button
      className="doneEditingButton"
      color="primary"
      onClick={handleDisableEditMode}
      size="small"
      variant="outlined"
    >
      Done Editing
    </Button>
  ), [handleDisableEditMode]);

  return (
    <StyledRoot>
      <Flex
        alignItems="flex-start"
        className={clsx('topToolbarContainer', isEditMode && 'editMode')}
        justifyContent="space-between"
      >
        <Flex>
          {!isEditMode && addButtonProps && (
            <SubscriberAddButton
              {...addButtonProps}
              disabled={isEditMode}
              marginRight={24}
            />
          )}

          {!isEditMode && !addButtonProps && customAddButton}

          {isEditMode && <EditModeText />}
        </Flex>

        <Flex className={clsx('searchAndActionsContainer', isEditMode && 'editMode')}>
          {isHydrating && <RefreshingIcon sx={{ marginX: 2 }} />}

          {withSearch && (
            <Input
              {...quickFieldProps}
              endAdornment={(
                <InputAdornment
                  position="end"
                  sx={{ width: 30 }}
                >
                  {quickFieldProps.value && (
                    <IconButton
                      onClick={handleClearQuickFilterValue}
                      size="small"
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  )}
                </InputAdornment>
              )}
              placeholder="Search..."
              size="small"
              startAdornment={(
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )}
            />
          )}

          {withEditMode && !isEditMode && (
            <EnhancedIconButton
              className="editIconButton"
              icon={<EditIcon fontSize="small" />}
              onClick={isEditMode
                ? handleDisableEditMode
                : onToggleEditMode}
              size="small"
              tooltip={`${isEditMode ? 'Disable' : 'Enable'} Edit Mode`}
              tooltipPlacement={shouldShowMoreActionsMenu
                ? 'bottom'
                : 'bottom-end'}
            />
          )}

          {shouldShowMoreActionsMenu && !isEditMode && (
            <ToolbarMoreActionsIconMenu moreActions={toolbarActions} />
          )}

          {isEditMode && doneEditingButton}
        </Flex>
      </Flex>

      <Box
        className="bottomToolbarContainer"
        paddingTop={1}
      >
        <GridToolbarContainer>
          <Tooltip
            placement="bottom-start"
            title={columnsButtonTooltipTitle}
          >
            <GridToolbarColumnsButton />
          </Tooltip>

          <GridToolbarFilterButton ref={setFilterButtonEl} />
          {!hideExport && (
            <GridToolbarExport
              printOptions={{
                hideToolbar: true,
                includeCheckboxes: true,
              }}
            />
          )}
        </GridToolbarContainer>
      </Box>

      <ConfirmationDialog
        confirmButtonAction={handleClose}
        confirmButtonText="Got it"
        description={sortingTipDescription}
        handleClose={handleClose}
        hideDeclineButton
        open={isOpen}
        title="Sort Multiple Columns"
        useCustomText
      />

      {globalEditContract?.data?.data.contract.fields && globalEditResource && (
        <GlobalEditDialog
          fields={globalEditContract.data.data.contract.fields}
          globalEditResource={globalEditResource}
          isOpen={isGlobalEditOpen}
          onClose={toggleIsGlobalEditOpen}
          rowIds={globalEditType === 'filtered' ? filteredIds : selectedIds}
        />
      )}
    </StyledRoot>
  );
};

export default EnhancedGridToolbar;
