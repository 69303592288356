// External Dependencies
import { FC } from 'react';
import { useNavigate } from '@reach/router';

// Internal Dependencies
import { navigateSearch } from 'utils/lib/navigate_search';
import { useGetOrganizationTypes } from 'gql/queries';
import { useParsedSearch } from 'hooks/useParsedSearch';
import District from 'components/shared/District';
import FilterButton from 'components/shared/FilterButton';

// Local Dependencies
import { useGetUniformFilterOptions } from './data';

// Local Typings
export interface IFilters {
  categoryId: string | null;
  isCheckedOut: boolean | null;
  organizationId: string | null;
  organizationTypeId: number | null;
}
interface Option {
  id: string;
  label: string;
}

// Local Variables
const checkedOutOptions: Option[] = [
  {
    id: 'true',
    label: 'Checked Out',
  },
  {
    id: 'false',
    label: 'Unassigned',
  },
];

// Component Definition
const Filters: FC = () => {
  const navigate = useNavigate();
  const parsedSearch = useParsedSearch();

  const localFilters: IFilters = {
    categoryId: parsedSearch.categoryId ?? null,
    isCheckedOut: parsedSearch.isCheckedOut ?? null,
    organizationId: parsedSearch.organizationId ?? null,
    organizationTypeId: parsedSearch.organizationTypeId ?? null,
  };

  const { data: orgTypeData } = useGetOrganizationTypes();

  const { data: filterOptions } = useGetUniformFilterOptions(parsedSearch.organizationTypeId);

  const handleClickFilter = (
    key: 'categoryId' | 'isCheckedOut' | 'organizationId' | 'organizationTypeId',
    val: string | number | null,
  ) => () => {
    if (key === 'organizationTypeId') {
      localFilters.categoryId = null;
    }

    localFilters[key] = val as never;
    navigateSearch(
      navigate,
      {
        ...localFilters,
        page: 1,
      },
    );
  };

  const getCategoryButtonLabel = () => {
    const category = filterOptions?.uniformCategories
      .find((cat) => cat.id.toString() === localFilters.categoryId?.toString());

    return category?.label ?? 'Category';
  };

  const getIsCheckedOutLabel = () => {
    const status = checkedOutOptions
      .find((option) => option.id === localFilters.isCheckedOut?.toString());

    return status?.label ?? 'Checkout Status';
  };

  return (
    <District>
      {({ district }) => {
        const { organizations } = district;
        const selectedOrg = organizations.find((org) => org.id === localFilters.organizationId);
        const selectedOrgType = orgTypeData?.organizationTypes.find(
          (orgType) => Number(orgType.id) === localFilters.organizationTypeId,
        );

        return (
          <div>
            <FilterButton
              active={!!localFilters.organizationId}
              buttonChildren={selectedOrg ? selectedOrg.label : 'Organization'}
              id="organization"
              onClick={selectedOrg
                ? handleClickFilter('organizationId', null)
                : null}
              subButtons={organizations.map((org) => ({
                onClick: handleClickFilter('organizationId', org.id),
                selected: localFilters.organizationId === org.id,
                text: org.label,
              }))}
            />

            {orgTypeData && (
              <>
                <FilterButton
                  active={!!localFilters.organizationTypeId}
                  buttonChildren={selectedOrgType ? selectedOrgType.label : 'Organization Type'}
                  id="organization-type-id"
                  onClick={selectedOrgType
                    ? handleClickFilter('organizationTypeId', null)
                    : null}
                  subButtons={orgTypeData.organizationTypes.map((orgType) => ({
                    onClick: handleClickFilter('organizationTypeId', orgType.id),
                    selected: localFilters.organizationTypeId === Number(orgType.id),
                    text: orgType.label,
                  }))}
                />

                <FilterButton
                  active={localFilters.categoryId !== null}
                  buttonChildren={getCategoryButtonLabel()}
                  disabled={(filterOptions?.uniformCategories ?? []).length === 0}
                  id="categoryId"
                  onClick={localFilters.categoryId !== null
                    ? handleClickFilter('categoryId', null)
                    : null}
                  subButtons={filterOptions?.uniformCategories.map((option) => ({
                    onClick: handleClickFilter('categoryId', option.id),
                    selected: localFilters.categoryId?.toString() === option.id.toString(),
                    text: option.label,
                  })) ?? []}
                />
              </>
            )}

            <FilterButton
              active={localFilters.isCheckedOut !== null}
              buttonChildren={getIsCheckedOutLabel()}
              id="checkout-status"
              onClick={localFilters.isCheckedOut !== null
                ? handleClickFilter('isCheckedOut', null)
                : null}
              subButtons={checkedOutOptions.map((option) => ({
                onClick: handleClickFilter('isCheckedOut', option.id),
                selected: localFilters.isCheckedOut?.toString() === option.id.toString(),
                text: option.label,
              }))}
            />
          </div>
        );
      }}
    </District>
  );
};

export default Filters;
